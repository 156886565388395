import React from "react";
import { Route,Switch } from "react-router";
import Loadable from "react-loadable";
import Loading from "../ui-molecules/Loading";
// import PatientOverview from "../ui-pages/UserHome/components/Content/Patients/PatientOverview";

const InvalidPage = Loadable({
  loader: () => import("../ui-pages/InvalidPage"),
  loading: Loading
});

// const Dashboard = Loadable({
//   loader: () => import("../ui-pages/UserHome/index"),
//   loading: Loading
// });

const ResetPassword = Loadable({
  loader: () => import("../ui-pages/Login/components/ResetPassword/ResetPassword"),
  loading: Loading
});

// const ForgotPassword = Loadable({
//   loader: () => import("../ui-pages/Login/components/ForgotPassword/ForgotPassword"),
//   loading: Loading
// });

// const SetupAccount = Loadable({
//   loader: () => import("../ui-pages/Login/components/SetupAccount/SetupAccount"),
//   loading: Loading
// });

// const AddPatient = Loadable({
//   loader: () => import("../ui-pages/UserHome/components/Content/Administration/addPatient"),
//   loading: Loading
// });

// const PatientSummary = Loadable({
//   loader: () => import("../ui-pages/UserHome/components/Content/Administration/components/TabContent/Patients/patientSummary"),
//   loading: Loading
// })

// const Admin = Loadable({
//   loader: () => import("../ui-pages/UserHome/components/Content/Administration/admin"),
//   loading: Loading
// })

const MainRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={InvalidPage} />     {/*Login component should be changed back to Dashboard*/}
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="*" component={InvalidPage} />
      {/* <Route path="/user-home" component={Dashboard} />
      <Route path="/login" component={Login} />      
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/setup-account" component={SetupAccount} />
      <Route path="/addPatient" component={AddPatient} />
      <Route path="/patient-summary" component={PatientSummary} />
      <Route path="/patient-overview" component={PatientOverview} />
      <Route path="/user-home/admin" component={Admin} />
      <Route exact path="/user-home/administration/patient-summary" component={PatientSummary} /> */}
      {/* <Redirect to="/" /> */}
    </Switch>
  )
}

export default MainRoutes;
